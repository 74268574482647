/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Layout as AntLayout } from 'antd';

import './layout.less';
import Header from './header';
import SEO from './seo';
import SidePanel from './sidePanel';
import Footer from './footer';
import { ContentfulSection } from '../../types/graphql-types';

const { Content } = AntLayout;

type Props = {
  sections?: Array<ContentfulSection>;
  activeSection?: ContentfulSection;
  nextSection?: ContentfulSection;
  previousSection?: ContentfulSection;
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children, sections, activeSection }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const activeId = activeSection && activeSection.id;
  let shortActiveId = activeId && activeId.substr(activeId.length - 5);
  shortActiveId = shortActiveId ?? '';

  return (
    <div className="container">
      <SEO title="PhotoDay Guide" />
      <Header siteTitle={data.site.siteMetadata.title} />
      <AntLayout className="guide-layout">
        {sections && (
          <SidePanel sections={sections} activeSection={activeSection} />
        )}
        <Content className={`guide-content ${shortActiveId}`}>{children}</Content>
      </AntLayout>
      <Footer />
    </div>
  );
};



Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
