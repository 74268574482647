import * as React from 'react';
import { Layout, List } from 'antd';
import { Link } from 'gatsby';
import { ContentfulSection } from '../../types/graphql-types'; // eslint-disable-line import/no-unresolved
import './sidePanel.less';

const { Sider } = Layout;

type Props = {
  sections: Array<ContentfulSection>;
  activeSection?: ContentfulSection;
};

const SidePanel: React.FC<Props> = ({ sections, activeSection }: Props) => {
  return (
    <Sider breakpoint="md" collapsedWidth={0} width={240}>
      <List>
        {sections.map(section => {
          return (
            <List.Item
              key={section.id}
              className={`${
                activeSection?.slug === section.slug ? 'active' : ''
              }`}
            >
              <Link to={`/${section.slug}`}>{section.title}</Link>
            </List.Item>
          );
        })}
      </List>
    </Sider>
  );
};

export default SidePanel;
