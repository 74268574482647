import * as React from 'react';
import { Link } from 'gatsby';
import { Layout } from 'antd';
import './header.less';

type Props = {
  siteTitle: string;
};

const Header: React.FC<Props> = ({ siteTitle }: Props) => (
  <Layout.Header>
    <Link to="/"><img src={require("../images/logo.png")} alt="PhotoDay Logo" /></Link>
    <a
      href="https://support.photoday.io"
      target="_blank"
      rel="noopener noreferrer"
    >
      Support
    </a>
  </Layout.Header>
);

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
