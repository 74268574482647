/* eslint-disable prettier/prettier */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import { BackTop, Layout as AntLayout, List } from 'antd'

import './footer.less';

const links = [
  {
    header: 'PhotoDay',
    links: [
      { name: 'How it Works', href: 'https://www.photoday.io/how_it_works/' },
      { name: 'Pricing', href: 'https://www.photoday.io/pricing' },
      { name: 'Login', href: 'http://studio.photoday.io/' },
      { name: 'Sign Up', href: 'https://www.photoday.io/signup' },
    ],
  },
  {
    header: 'Company',
    links: [
      { name: 'About', href: 'https://www.photoday.io/about_us/' },
      { name: 'Technology', href: 'https://www.photoday.io/technology/overview/' },
      { name: 'Lab Inquiry', href: 'https://www.photoday.io/lab_signup/' },
      { name: 'Blog', href: 'https://blog.photoday.io/' },
    ],
  },
  {
    header: 'Support',
    links: [
      { name: 'Articles', href: 'http://support.photoday.io/' },
      { name: 'PhotoDay Guide', href: 'https://guide.photoday.io/' },
      { name: 'Webinars', href: 'https://webinars.photoday.io/' },
      { name: 'Contact', href: 'https://www.photoday.io/contact' },
      { name: 'Updates', href: 'https://blog.photoday.io/version-updates/' },
      { name: 'System Status', href: 'https://status.photoday.io/' },
    ],
  },
];

const Footer: React.FC = () => {
  return (
    <AntLayout.Footer className="guide-footer">
      <div className="link-section">
        {links.map(link => (
          <ul
            className="links"
            key={link.header}
          >
            <li className="link-header">{link.header}</li>
            {link.links.map(innerLink => (
              <li className="link" key={innerLink.name}>
                <a href={innerLink.href} target="_blank" rel="noopener noreferrer">{innerLink.name}</a>
              </li>
            ))}
          </ul>
        ))}
      </div>
      <div className="bottom-footer">
        <ul>
          <li>Copyright © 2020 PhotoDay. All rights reserved.</li>
          <li>
            <a href="http://www.photoday.io/terms" target="_blank" rel="noopener noreferrer">Terms</a>
          </li>
          <li>
            <a href="http://www.photoday.io/privacy" target="_blank" rel="noopener noreferrer">Privacy</a>
          </li>
          <li>
            <a href="http://www.photoday.io/dmca" target="_blank" rel="noopener noreferrer">Copyright</a>
          </li>
          <li>
            <a href="http://www.photoday.io/brand_guidelines/" target="_blank" rel="noopener noreferrer">Brand Guidelines</a>
          </li>
          <li className="social-media-links">
            <a href="https://www.facebook.com/photoday.io" target="_blank" rel="noopener noreferrer">
              <img src={require("../images/icon_fb.png")} alt="fb" />
            </a>
            <a href="https://twitter.com/photoday_io" target="_blank" rel="noopener noreferrer">
              <img src={require("../images/icon_twitter.png")} alt="twitter" />
            </a>
            <a href="https://www.instagram.com/photoday.io/" target="_blank" rel="noopener noreferrer">
              <img src={require("../images/icon_instagram.png")} alt="instagram" />
            </a>
            <a href="https://www.youtube.com/channel/UCmA2pReWGq7G74H9zkeF6Jw" target="_blank" rel="noopener noreferrer">
              <img src={require("../images/icon_youtube.png")} alt="youtube" />
            </a>
            <a href="https://www.snapchat.com/add/photoday.io" target="_blank" rel="noopener noreferrer">
              <img src={require("../images/icon_snapchat.png")} alt="snapchat" />
            </a>
            <a href="https://www.linkedin.com/company/photoday/" target="_blank" rel="noopener noreferrer">
              <img src={require("../images/icon_linkedin.png")} alt="linkedin" />
            </a>
          </li>
        </ul>
      </div>
      <BackTop />
    </AntLayout.Footer>
  );
};

export default Footer;
